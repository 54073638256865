import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import { useTheme } from 'lib/context/ThemeContext';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import Component from 'src/helpers/Component/Component';
import { ToggleBannerTheme } from './ToggleBanner.theme';
import classNames from 'classnames';
import Headline from 'src/helpers/Headline/Headline';
import { getEnum } from 'lib/utils';
import HelperButton from './ToggleBannerHelper';
import BodyCopy from 'src/helpers/BodyCopy/BodyCopy';

export type ToggleBannerProps =
  Feature.EnterpriseWeb.AndersenWindows.Components.General.ToggleBanner.ToggleBanner;

const ToggleBanner = (props: ToggleBannerProps) => {
  const headlineLevel = getEnum<string>(props?.fields.headlineLevel);
  const LeftButton = {
    ...props?.fields?.LeftToggleLink,
    value: { ...props?.fields?.LeftToggleLink?.value, text: props?.fields?.LeftToggleText?.value },
  };
  const RightButton = {
    ...props?.fields?.RightToggleLink,
    value: {
      ...props?.fields?.RightToggleLink?.value,
      text: props?.fields?.RightToggleText?.value,
    },
  };
  const { themeData } = useTheme(ToggleBannerTheme);
  const containerWidth =
    getEnum<'fullBleed' | 'fullWidth'>(props.fields?.containerWidth) ?? 'fullWidth';
  const defaultHover =
    getEnum<string | string>(props.fields.ToggleChkSelection) === 'rightToggle' ? 2 : 1;

  const iconReverse = defaultHover === 1 ? 'flex-row-reverse' : '';
  return (
    <Component
      dataComponent="general/togglebanner"
      variant="lg"
      sectionWrapperClasses={containerWidth === 'fullBleed' ? 'bg-primary' : ''}
      gap="gap-x-0"
      padding="px-0"
      backgroundVariant="primary"
      grid="section-grid grid grid-cols-2 md:grid-cols-12 bg-primary"
      {...props}
    >
      <div className={themeData.classes.mainWrapper}>
        <div className={themeData.classes.headlineWrapper}>
          <Headline defaultTag={headlineLevel} {...props} />
          <BodyCopy {...props} />
        </div>
        <div className={themeData.classes.buttonWrappers}>
          <HelperButton
            icon={
              defaultHover === 2
                ? { fields: { Value: { value: 'checkround' } } }
                : props?.fields?.RightToggleIcon
            }
            field={RightButton}
            classes={classNames(
              themeData.classes.buttonRightClass,
              themeData.classes.buttonHoverClass
            )}
          />
          <HelperButton
            icon={
              defaultHover === 1
                ? { fields: { Value: { value: 'checkround' } } }
                : props?.fields?.LeftToggleIcon
            }
            classes={classNames(
              `order-[-1] flex relative gap-2 pl-0 text-center ${iconReverse}`,
              themeData.classes.buttonLeftClass
            )}
            field={LeftButton}
          />
        </div>
      </div>
    </Component>
  );
};

export default withDatasourceCheck()<ToggleBannerProps>(ToggleBanner);
